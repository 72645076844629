import React, { useState, useRef, useEffect } from 'react';

import styled from 'styled-components';

import { FilterContainer } from './CommonStyles';
import PriceTag from 'assets/images/icons/pricetags-outline.svg';
import PriceTagMany from 'assets/images/icons/pricetags-outline_many.svg';
import theme from 'config/theme';
import { ReactComponent as Icon } from 'assets/images/icons/Icon-dropdown.svg';

import { ClosablePopup } from './ClosablePopup';

const { colors } = theme;

const sortOptions = ['price_desc', 'price_asc'];

const SortByFilter = ({ filterParams, updateFilters }) => {
	const openPopupTriggerRef = useRef();
	const [isOpen, setIsOpen] = useState(false);

	useEffect(() => {
		// pre-load images
		const image1 = new Image();
		const image2 = new Image();
		image1.src = PriceTag;
		image2.src = PriceTagMany;
	}, []);

	const handleApply = sortBy => {
		setIsOpen(false);
		updateFilters({ sort_by: sortBy });
	};

	const getSortOptionDisplayName = option => {
		switch (option) {
			case 'price_asc':
				return 'Price low to high';
			case 'price_desc':
				return 'Price high to low';
			default:
				return '';
		}
	};

	return (
		<FilterContainer>
			<TriggerContainer
				ref={openPopupTriggerRef}
				active={filterParams.sort_by}
				onClick={() => setIsOpen(prev => !prev)}
			>
				{getSortOptionDisplayName(filterParams.sort_by) || 'Sort by'}
				<DropdownIcon />
			</TriggerContainer>
			{isOpen && (
				<ClosablePopup
					triggerElementRef={openPopupTriggerRef}
					onClose={() => setIsOpen(false)}
					width="200px"
				>
					<ContentContainer>
						{sortOptions.map(option => (
							<SortOption
								active={filterParams.sort_by === option}
								onClick={() => handleApply(option)}
							>
								<SortTitle>{getSortOptionDisplayName(option)}</SortTitle>
							</SortOption>
						))}
					</ContentContainer>
				</ClosablePopup>
			)}
		</FilterContainer>
	);
};

export default SortByFilter;

const SortOption = styled.div`
	width: 100%;
	padding: 8px 16px;
	display: flex;
	align-items: center;
	cursor: pointer;
	${({ active }) => active && `color: ${colors.pink}`};
	&:hover {
		background-color: ${colors.grey01};
	}
`;

const SortTitle = styled.div`
	font-weight: 500;
`;

const DropdownIcon = styled(Icon)`
	width: 10px;
	margin-left: 8px;
	margin-top: 6px;
`;

const ContentContainer = styled.div`
	padding-top: 12px;
	padding-bottom: 12px;
`;

const TriggerContainer = styled.div`
	font-size: 16px;
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 40px;
	padding: 0 12px;
	cursor: pointer;
	user-select: none;
	color: ${colors.darkBlue};
	transition: all 200ms;
	box-sizing: border-box;
`;
