import React, { useState, useRef } from 'react';
import {
	PillContainer,
	PopupBottom,
	PopupInner,
	PopupButtonContainer,
	FilterContainer,
} from './CommonStyles';
import { PriceRange } from './PriceRange';
import { ClosablePopup } from './ClosablePopup';
import formatPriceText from '../formatPriceText';
import { Button } from 'lib/Button';

const PriceRangePopup = ({
	filterParams,
	triggerElementRef,
	onApply,
	onClose,
	priceFilterConfig,
}) => {
	const [range, setRange] = useState({
		min: filterParams.min_price || priceFilterConfig.range_min,
		max: filterParams.max_price || priceFilterConfig.range_max,
	});

	const handleSaveClick = () => {
		onApply({
			min: range.min,
			max: range.max < priceFilterConfig.range_max ? range.max : undefined,
		});
		onClose();
	};

	return (
		<ClosablePopup triggerElementRef={triggerElementRef} onClose={onClose}>
			<PopupInner>
				<PriceRange
					max={priceFilterConfig.range_max}
					min={priceFilterConfig.range_min}
					values={range}
					setValue={setRange}
					symbol={priceFilterConfig.symbol}
				/>
			</PopupInner>
			<PopupBottom>
				<PopupButtonContainer>
					<Button isOutline noMargin onClick={handleSaveClick}>
						Apply
					</Button>
				</PopupButtonContainer>
			</PopupBottom>
		</ClosablePopup>
	);
};

const PriceFilter = ({
	open,
	filterParams,
	openPopup,
	updateFilters,
	closePopup,
	priceFilterConfig,
}) => {
	const openPopupTriggerRef = useRef();

	const handlePillClick = () => {
		if (open) {
			closePopup();
			return;
		}
		openPopup('Price');
	};

	const handleApplyRange = ({ min, max }) => {
		updateFilters({
			min_price: min,
			max_price: max,
		});
	};

	const priceText = formatPriceText(
		filterParams.min_price,
		filterParams.max_price,
		priceFilterConfig.range_min,
		priceFilterConfig.range_max,
		priceFilterConfig.symbol,
	);

	return (
		<FilterContainer>
			<PillContainer ref={openPopupTriggerRef} active={!!priceText} onClick={handlePillClick}>
				{priceText || 'Price'}
			</PillContainer>
			{open && (
				<PriceRangePopup
					filterParams={filterParams}
					triggerElementRef={openPopupTriggerRef}
					onApply={handleApplyRange}
					onClose={closePopup}
					priceFilterConfig={priceFilterConfig}
				/>
			)}
		</FilterContainer>
	);
};

export default PriceFilter;
