import React, { useState, useEffect } from 'react';

import styled from 'styled-components';

import api from 'api';
import theme from 'config/theme';
import authContainer from 'containers/auth';
import modalContainer from 'containers/modal';
import { ModalType } from 'constants/modalTypes';
import { FavouriteButton } from 'lib/FavouriteButton';
import CustomCarousel from './Carousel';
import { ListingVerifications } from './ListingVerifications';
import numberFormatService from 'services/numberFormatService';
import listingService from 'services/listingService';
import ListingFeatureIcons from './ListingFeatureIcons';
import { analyticsService, EventName } from 'services/analyticsService';
import Tag from '../../assets/images/digs-listing/tag.png';
import { TrustedLandlordContainer, TrustedLandlordImage, TrustedLandlordText } from './styles';
import urlUtils from 'utils/urlUtils';
import { PropertyType } from 'models/digs';

const { boxShadow, fonts, fontSizes, fontWeights, colors, zIndices } = theme;

const ListingCard = ({ loading = false, landlord, ...props }) => {
	const [favourite, setFavourite] = useState(props.favourite);
	const listing = props;

	useEffect(() => {
		setFavourite(props.favourite);
	}, [props.favourite]);

	const getSubTypeText = ref => {
		for (const [key, val] of Object.entries(PropertyType)) {
			if (val === ref) {
				return key;
			}
		}
		return '';
	};

	const handleFavouriteClick = uuid => {
		if (!authContainer.state.token) {
			modalContainer.set(ModalType.Login);
			return;
		}
		if (!favourite) {
			api.favouritesV2.createFavourite(uuid);
			setFavourite(true);
			analyticsService.trackEvent(EventName.FavouriteClick, {
				digs_listing_uuid: props.uuid || '',
			});
		} else {
			api.favouritesV2.deleteFavourite(uuid);
			setFavourite(false);
		}
	};

	const roomsCount = props.rooms ? props.rooms.length : 0;

	return (
		<Container
			onClick={props.onClick}
			onMouseOver={props.onMouseOver}
			onMouseOut={props.onMouseOut}
		>
			<CarouselContainer>
				{loading ? (
					<ShimmerDiv />
				) : (
					<CustomCarousel
						images={props.photos.map(({ url }) =>
							urlUtils.appendQueryParams(url, 'w=300&h=200&fit=crop&auto=compress&auto=format'),
						)}
					/>
				)}
				<ListingVerifications
					isTop
					nsfas_accredited={props.nsfas_accredited}
					varsity_college_accredited={props.varsity_college_accredited}
					aie_accredited={props.aie_accredited}
				/>
				{landlord?.is_trusted_landlord && (
					<TrustedLandlordContainer>
						<TrustedLandlordImage src={Tag} />
						<TrustedLandlordText>Trusted landlord</TrustedLandlordText>
					</TrustedLandlordContainer>
				)}
			</CarouselContainer>
			<ContentContainer>
				<Top>
					<AddressContainer>
						<Address>{listingService.getLocationText(props.location)}</Address>
					</AddressContainer>
					<FavouriteButton isChecked={favourite} onClick={() => handleFavouriteClick(props.uuid)} />
				</Top>
				<RoomKey>
					{props.lease_type === 'multi'
						? `${getSubTypeText(props.sub_type)} • ${roomsCount} room type${
								roomsCount > 1 ? 's' : ''
						  } available`
						: `Entire place • ${roomsCount} bedroom`}
				</RoomKey>
				<ListingTitle>{props.title}</ListingTitle>
				<Bottom>
					<PriceContainer>
						<Price>
							{props.display_price_from && 'from '}
							{numberFormatService.formatCurrency(
								props.price,
								listingService.getCurrencySymbol(props.currency),
							)}
						</Price>
						<PerText>per {listingService.getBillingCyclePeriod(props)}</PerText>
					</PriceContainer>
					<ListingFeatureIcons listing={listing} />
				</Bottom>
			</ContentContainer>
		</Container>
	);
};

export default ListingCard;

const Container = styled.div`
	border-radius: 16px;
	min-height: 200px;
	background-color: white;
	margin: 16px;
	margin-bottom: 24px;
	display: flex;
	overflow: hidden;
	cursor: pointer;
	user-select: none;
	box-shadow: ${boxShadow.small};
`;

const PriceContainer = styled.div`
	display: flex;
	align-items: flex-end;
`;

const PerText = styled.div`
	font-size: 14px;
	color: ${colors.grey60};
	font-weight: 400;
	margin-top: 2px;
`;

const CarouselContainer = styled.div`
	width: 300px;
	display: block;
	position: relative;
`;

const ContentContainer = styled.div`
	flex-grow: 1;
	position: relative;
	padding: 16px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	font-family: ${fonts.Gilroy};
`;

const ListingTitle = styled.div`
	font-size: ${fontSizes.medium};
	font-weight: ${fontWeights.bold};
	max-width: 250px;
	line-height: 1.2em;
`;

const RoomKey = styled.div`
	font-size: ${fontSizes.medium};
	font-weight: ${fontWeights.bold};
	color: ${colors.pink};
`;

const Price = styled.div`
	font-size: ${fontSizes.large};
	font-weight: ${fontWeights.bold};
	display: inline-block;
	margin-right: 8px;
`;

const Bottom = styled.div`
	display: flex;
	justify-content: space-between;
`;

const Top = styled.div`
	display: flex;
	justify-content: space-between;
`;

const Address = styled.div`
	font-size: ${fontSizes.small};
	line-height: 1.3em;
	display: flex;
	align-items: center;
`;

const ShimmerDiv = styled.div`
	background-color: gray;
	height: 100%;
	width: 100%;
	animation: shimmer 4s infinite;
	background: linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%);
	background-size: 1000px 100%;
	@keyframes shimmer {
		0% {
			background-position: -1000px 0;
		}
		100% {
			background-position: 1000px 0;
		}
	}
`;

const AddressContainer = styled.div`
	display: flex;
`;
