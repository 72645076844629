import React, { useEffect, useRef } from 'react';

import styled from 'styled-components';

import theme from 'config/theme';

const { colors, zIndices } = theme;

const Container = styled.div<{ rightAlign?: boolean }>`
	min-height: 100px;
	border: 1px solid ${colors.shadedGray};
	position: absolute;
	background-color: ${colors.white};
	border-radius: 16px;
	top: calc(100% + 8px);
	z-index: ${zIndices.modal};
	box-shadow: rgba(0, 0, 0, 0.2) 0 0 20px;
	${({ rightAlign }) => (rightAlign ? 'right: 0;' : '')}

	@media (max-width: 768px) {
		max-height: 100vh;
		position: fixed;
		bottom: 0;
		top: unset;
		left: 0;
		width: 100vw;
		padding: 0;
	}
`;

interface PopupProps {
	triggerElementRef?: any;
	children: any;
	rightAlign?: boolean;
	onClose(): void;
}

export const Popup = ({ triggerElementRef, children, rightAlign, onClose }: PopupProps) => {
	const popupElementRef = useRef<any>();

	useEffect(() => {
		const eventName = 'mousedown';
		const clickHandler = (e: Event) => {
			if (popupElementRef.current.contains(e.target)) {
				return;
			}
			if (
				triggerElementRef &&
				triggerElementRef.current &&
				e.target === triggerElementRef.current
			) {
				return;
			}
			onClose();
		};
		document.addEventListener(eventName, clickHandler);

		return () => document.removeEventListener(eventName, clickHandler);
		// TODO: Fix and test
		// eslint-disable-next-line
	}, []);

	return (
		<Container rightAlign={rightAlign} ref={popupElementRef}>
			{children}
		</Container>
	);
};
