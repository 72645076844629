import numberFormatService from 'services/numberFormatService';

export const formatPriceText = (
	min: number,
	max: number,
	rangeMin: number,
	rangeMax: number,
	symbol: string,
): string => {
	const maxFormatted = max ? numberFormatService.formatCurrency(max, symbol) : '';
	const minFormatted = min ? numberFormatService.formatCurrency(min, symbol) : '';
	if (min && min !== rangeMin && max && max !== rangeMax) {
		return `${minFormatted} - ${maxFormatted}`;
	}
	if (max && max !== rangeMax) {
		return `Up to ${maxFormatted}`;
	}
	if (min && min !== rangeMin) {
		return `${minFormatted}+`;
	}
	return '';
};

export default formatPriceText;
