import { BREAKPOINT_TABLET_PX } from 'constants/breakPoints';
import { useScreenWidth } from 'hooks/useScreenWidth';
import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
	padding-left: 16px;
`;

const InFeedAd = () => {
	const width = useScreenWidth();
	const isMobile = width <= BREAKPOINT_TABLET_PX;

	if (isMobile) {
		return (
			<Container>
				<script
					async
					src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6406660131965644"
					crossOrigin="anonymous"
				></script>
				<ins
					className="adsbygoogle"
					style={{ display: 'block' }}
					data-ad-format="fluid"
					data-ad-layout-key="-6e+bz+1h-9+dt"
					data-ad-client="ca-pub-6406660131965644"
					data-ad-slot="8121493634"
				></ins>
				<script>(adsbygoogle = window.adsbygoogle || []).push({});</script>
			</Container>
		);
	}

	return (
		<Container>
			<script
				async
				src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6406660131965644"
				crossOrigin="anonymous"
			></script>
			<ins
				className="adsbygoogle"
				style={{ display: 'block' }}
				data-ad-format="fluid"
				data-ad-layout-key="-fb+5w+4e-db+86"
				data-ad-client="ca-pub-6406660131965644"
				data-ad-slot="7976548620"
			></ins>
			<script>(adsbygoogle = window.adsbygoogle || []).push({});</script>
		</Container>
	);
};

export default InFeedAd;
