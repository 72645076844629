import React, { useState } from 'react';

import styled from 'styled-components';
import 'react-input-range/lib/css/index.css';

import PriceFilter from './filters/PriceFilter';
import LocationFilter from './filters/LocationFilter';
import TenantsFilter from './filters/TenantsFilter';
import AllFilters from './filters/AllFilters';
import theme from 'config/theme';
import { PillContainer, FilterContainer } from './filters/CommonStyles';
import { BREAKPOINT_MOBILE_PX } from 'constants/breakPoints';

import { NsfasFilter } from './filters/NsfasFilter';
import { featureFlagContainer, FeatureFlag } from 'containers/featureFlags';

const { colors } = theme;

const FILTER_TYPES = {
	Location: 'location',
	Tenants: 'tenants',
	Price: 'Price',
	Nsfas: 'nsfas',
};

const MobileOnly = styled.div`
	flex-shrink: 0;
	@media (min-width: ${BREAKPOINT_MOBILE_PX}px) {
		display: none;
	}
`;

const Filters = ({
	updateFilters,
	filterParams,
	features,
	addAlertPillRef,
	onAddAlertClick,
	priceFilterConfig,
	isInternationalLocation,
	showLocationWarningTooltip,
	onWarningTooltipClose,
}) => {
	const [open, setOpen] = useState(null);

	const openPopup = ref => {
		if (ref !== open) {
			setOpen(ref);
		} else {
			setOpen(null);
		}
	};

	const closePopup = () => {
		setOpen(null);
	};

	const COMMON_PROPS = {
		updateFilters: updateFilters,
		filterParams: filterParams,
		closePopup: closePopup,
		openPopup: openPopup,
	};

	const handleClearFilters = () => {
		updateFilters();
	};

	return (
		<Container>
			<AllFilters
				{...COMMON_PROPS}
				features={features}
				isInternationalLocation={isInternationalLocation}
				priceFilterConfig={priceFilterConfig}
				onClearAllClick={handleClearFilters}
			/>
			<LocationFilter
				isOpen={open === FILTER_TYPES.Location}
				onOpenPopup={() => openPopup(FILTER_TYPES.Location)}
				onClosePopup={() => openPopup(null)}
				showLocationWarningTooltip={showLocationWarningTooltip}
				onTooltipClose={onWarningTooltipClose}
			/>
			<PriceFilter
				open={open === FILTER_TYPES.Price}
				{...COMMON_PROPS}
				priceFilterConfig={priceFilterConfig}
			/>
			<TenantsFilter
				isOpen={open === FILTER_TYPES.Tenants}
				filterParams={filterParams}
				onUpdateFilters={updateFilters}
				onOpenPopup={() => openPopup(FILTER_TYPES.Tenants)}
				onClosePopup={() => openPopup(null)}
			/>
			{!isInternationalLocation && (
				<NsfasFilter
					isOpen={open === FILTER_TYPES.Nsfas}
					filterParams={filterParams}
					onUpdateFilters={updateFilters}
					onOpenPopup={() => openPopup(FILTER_TYPES.Nsfas)}
					onClosePopup={() => openPopup(null)}
				/>
			)}
			<ClearPill onClick={handleClearFilters}>Clear all</ClearPill>
			{featureFlagContainer.isEnabled(FeatureFlag.DigsAlert) && (
				<MobileOnly>
					<FilterContainer borderLeft>
						<PillContainer active={true} ref={addAlertPillRef} onClick={onAddAlertClick}>
							+ Add alert
						</PillContainer>
					</FilterContainer>
				</MobileOnly>
			)}
		</Container>
	);
};

export default Filters;

const Container = styled.div`
	width: 100%;
	padding: 8px 16px;
	padding-bottom: 0;
	display: flex;

	@media (max-width: 768px) {
		flex-wrap: nowrap;
		overflow-x: auto;
	}
`;

const ClearPill = styled.div`
	color: ${colors.darkBlue};
	display: inline-flex;
	align-items: center;
	padding: 8px;
	font-size: 14px;
	font-weight: 500;
	cursor: pointer;
	user-select: none;
	transition: all 200ms;
	white-space: nowrap;
	margin-bottom: 8px;
`;
