import React, { useState, useRef } from 'react';

import styled from 'styled-components';

import {
	FilterContainer,
	PillContainer,
	PopupBottom,
	PopupInner,
	PopupButtonContainer,
} from './CommonStyles';
import { ClosablePopup } from './ClosablePopup';
import CheckboxRow from 'lib/CheckboxRow';
import { Button } from 'lib/Button';
import { imgixNsfasLogoUrl } from 'config/imgix';
import theme from 'config/theme';

const { colors, boxShadow } = theme;

const nsfasFilterParamName = 'nsfas_accredited';

interface NsfasFilterProps {
	isOpen: boolean;
	filterParams: any;
	onOpenPopup(): void;
	onClosePopup(): void;
	onUpdateFilters(filters: any): void;
}

export const NsfasFilter = ({
	isOpen,
	filterParams,
	onOpenPopup,
	onClosePopup,
	onUpdateFilters,
}: NsfasFilterProps) => {
	const openPopupTriggerRef = useRef();

	const handleSave = (value: boolean) => {
		onUpdateFilters({ [nsfasFilterParamName]: value || undefined });
		onClosePopup();
	};

	return (
		<FilterContainer>
			<PillContainer
				ref={openPopupTriggerRef as any}
				active={filterParams[nsfasFilterParamName]}
				onClick={onOpenPopup}
			>
				NSFAS
			</PillContainer>
			{isOpen && (
				<NsfasPopup
					filterParams={filterParams}
					openPopupTriggerRef={openPopupTriggerRef}
					onClose={onClosePopup}
					onSave={handleSave}
				/>
			)}
		</FilterContainer>
	);
};

interface NsfasPopupProps {
	filterParams: any;
	openPopupTriggerRef: React.MutableRefObject<any>;
	onClose(): void;
	onSave(value: boolean): void;
}

const NsfasPopup = ({ filterParams, openPopupTriggerRef, onClose, onSave }: NsfasPopupProps) => {
	const [isChecked, setIsChecked] = useState<boolean>(filterParams[nsfasFilterParamName]);

	return (
		<ClosablePopup triggerElementRef={openPopupTriggerRef} onClose={onClose}>
			<PopupInner>
				<Container>
					<LogoContainer>
						<Logo src={`${imgixNsfasLogoUrl}?auto=compress&auto=format`} />
					</LogoContainer>
					<CheckboxContainer>
						<CheckboxRow
							text={'Show only NSFAS accredited accommodation'}
							checked={isChecked}
							onClick={() => setIsChecked(isChecked => !isChecked)}
						/>
					</CheckboxContainer>
				</Container>
			</PopupInner>
			<PopupBottom>
				<PopupButtonContainer>
					<Button isOutline noMargin onClick={() => onSave(isChecked)}>
						Apply
					</Button>
				</PopupButtonContainer>
			</PopupBottom>
		</ClosablePopup>
	);
};

const Container = styled.div`
	display: flex;
	align-items: center;
`;

const CheckboxContainer = styled.div`
	width: 100%;
	margin-top: 16px;
	margin-left: 16px;
`;

const LogoContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	background: ${colors.white};
	border: 1px solid ${colors.grey10};
	box-shadow: ${boxShadow.small};
	height: 64px;
	width: 64px;
	min-height: 64px;
	min-width: 64px;
`;

const Logo = styled.img`
	width: 50px;
	max-width: 50px;
`;
