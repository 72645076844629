import React from 'react';
import styled from 'styled-components';
import theme from 'config/theme';
import numberFormatService from 'services/numberFormatService';
import listingService from 'services/listingService';

const { colors } = theme;

const PriceTag = ({ listing, selected = false }) => {
	return (
		<Container selected={selected}>
			{numberFormatService.formatCurrency(
				listing.price,
				listingService.getCurrencySymbol(listing.currency),
			)}
		</Container>
	);
};

export default PriceTag;

const scaleWhenSelected = 1.1;

const Container = styled.div`
	padding: 4px 12px;
	border-radius: 100px;
	background-color: ${({ selected }) => (selected ? colors.darkBlue : 'white')};
	color: ${({ selected }) => (selected ? 'white' : 'black')};
	border: #cdcdcd 1px solid;
	font-weight: 700;
	font-size: 16px;
	box-shadow: lightgray 0 0 1px;
	transition: transform 0.2s;
	cursor: pointer;
	user-select: none;
	transform: ${({ selected }) => (selected ? `scale(${scaleWhenSelected})` : 'scale(1)')};

	:hover {
		transform: scale(${scaleWhenSelected});
	}
`;
