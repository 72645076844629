import React from 'react';

import styled from 'styled-components';

import theme from 'config/theme';
import Carousel from './Carousel';
import { universityDistanceService } from 'services/universityDistanceService';
import listingService from 'services/listingService';
import numberFormatService from 'services/numberFormatService';
import urlUtils from 'utils/urlUtils';
import { PropertyType } from 'models/digs';

const { fonts, fontSizes, fontWeights, colors, zIndices } = theme;

// TODO: Replace this component with ListingCardMobile
const ListingCardMap = ({ loading = false, ...props }) => {
	const getSubTypeText = ref => {
		for (const [key, val] of Object.entries(PropertyType)) {
			if (val === ref) {
				return key;
			}
		}
		return '';
	};

	return (
		<Container onClick={props.onClick}>
			<CarouselContainer>
				{loading ? (
					<ShimmerDiv />
				) : (
					<Carousel
						images={props.photos.map(item =>
							urlUtils.appendQueryParams(
								item.url,
								'w=300&h=200&fit=crop&auto=compress&auto=format',
							),
						)}
					/>
				)}
			</CarouselContainer>
			<ContentContainer>
				<Top>
					<div>
						<Address>{listingService.getLocationText(props.location)}</Address>
						<Address>
							{universityDistanceService.getUniversityDistanceText(props.university_distances)}
						</Address>
					</div>
				</Top>
				<RoomKey>
					{props.lease_type === 'multi'
						? `${props.rooms.length} room type${props.rooms.length > 1 ? 's' : ''} available`
						: `Entire place • ${props.rooms.length} bedroom`}
				</RoomKey>
				<ListingTitle>{props.title}</ListingTitle>
				<Bottom>
					<PriceContainer>
						<Price>
							{props.display_price_from && 'from '}
							{numberFormatService.formatCurrency(
								props.price,
								listingService.getCurrencySymbol(props.currency),
							)}
						</Price>
						<PerText>per {listingService.getBillingCyclePeriod(props)}</PerText>
					</PriceContainer>
					<div>{getSubTypeText(props.sub_type)}</div>
				</Bottom>
			</ContentContainer>
		</Container>
	);
};

export default ListingCardMap;

const Container = styled.div`
	border-radius: 16px;
	min-height: 200px;
	background-color: white;
	display: flex;
	z-index: ${zIndices.modal};
	width: 300px;
	height: 350px;
	overflow: hidden;
	cursor: pointer;
	flex-direction: column;
`;

const PriceContainer = styled.div`
	display: flex;
	align-items: flex-end;
`;

const PerText = styled.div`
	font-size: 12px;
	color: ${colors.grey60};
	font-weight: 400;
	margin-top: 2px;
`;

const CarouselContainer = styled.div`
	height: 200px;
	display: block;
	position: relative;
`;

const ContentContainer = styled.div`
	flex-grow: 1;
	position: relative;
	padding: 16px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	font-family: ${fonts.Gilroy};
`;

const ListingTitle = styled.div`
	font-size: ${fontSizes.large};
	font-weight: ${fontWeights.bold};
	max-width: 250px;
	line-height: 1.2em;
`;

const RoomKey = styled.div`
	font-size: ${fontSizes.medium};
	font-weight: ${fontWeights.bold};
	color: ${colors.pink};
`;

const Price = styled.div`
	font-size: ${fontSizes.large};
	font-weight: ${fontWeights.bold};
	display: inline-block;
	margin-right: 8px;
`;

const Bottom = styled.div`
	display: flex;
	justify-content: space-between;
`;

const Top = styled.div`
	display: flex;
	justify-content: space-between;
`;

const Address = styled.div`
	font-size: ${fontSizes.small};
	line-height: 1.3em;
`;

const ShimmerDiv = styled.div`
	background-color: gray;
	height: 100%;
	width: 100%;
	animation: shimmer 4s infinite;
	background: linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%);
	background-size: 1000px 100%;
	@keyframes shimmer {
		0% {
			background-position: -1000px 0;
		}
		100% {
			background-position: 1000px 0;
		}
	}
`;
