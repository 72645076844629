import React, { useState, useRef, useEffect } from 'react';

import { Button } from 'lib/Button';

import {
	FilterContainer,
	PillContainer,
	PopupInner,
	PopupBottom,
	PopupButtonContainer,
} from './CommonStyles';
import { ClosablePopup } from './ClosablePopup';
import TenantsFilterInput from './TenantsFilterInput';

const TENANTS_FILTER_PARAM_NAME = 'tenants';

interface TenantsFiterProps {
	isOpen: boolean;
	filterParams: any;
	onOpenPopup(): void;
	onClosePopup(): void;
	onUpdateFilters(filters: any): void;
}

const TenantsFiter = ({
	isOpen,
	filterParams,
	onOpenPopup,
	onClosePopup,
	onUpdateFilters,
}: TenantsFiterProps) => {
	const openPopupTriggerRef = useRef();
	const [tenantCount, setTenantCount] = useState<number>(0);

	useEffect(() => {
		setTenantCount(filterParams[TENANTS_FILTER_PARAM_NAME] || 0);
	}, [filterParams]);

	const handleClosePopup = () => {
		setTenantCount(filterParams[TENANTS_FILTER_PARAM_NAME] || 0);
		onClosePopup();
	};

	const handlePillClick = () => {
		if (isOpen) {
			onClosePopup();
			return;
		}
		onOpenPopup();
	};

	const handleApply = () => {
		onUpdateFilters({ [TENANTS_FILTER_PARAM_NAME]: tenantCount || undefined });
		onClosePopup();
	};

	return (
		<FilterContainer>
			<PillContainer
				ref={openPopupTriggerRef as any}
				active={Boolean(filterParams[TENANTS_FILTER_PARAM_NAME])}
				onClick={handlePillClick}
			>
				{filterParams[TENANTS_FILTER_PARAM_NAME]
					? `${filterParams[TENANTS_FILTER_PARAM_NAME]}+ Tenants`
					: 'Number of tenants'}
			</PillContainer>
			{isOpen && (
				<ClosablePopup triggerElementRef={openPopupTriggerRef} onClose={handleClosePopup}>
					<PopupInner>
						<TenantsFilterInput count={tenantCount} onCountChange={setTenantCount} />
					</PopupInner>
					<PopupBottom>
						<PopupButtonContainer>
							<Button isOutline noMargin onClick={handleApply}>
								Apply
							</Button>
						</PopupButtonContainer>
					</PopupBottom>
				</ClosablePopup>
			)}
		</FilterContainer>
	);
};

export default TenantsFiter;
