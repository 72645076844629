import { Container } from 'unstated';

interface ListingState {
	hoverListingId: string;
}

const INITIAL_STATE: ListingState = {
	hoverListingId: '',
};

class ListingContainer extends Container<ListingState> {
	state = INITIAL_STATE;

	setHoverListing(id: string): void {
		this.setState(state => ({
			...state,
			hoverListingId: id,
		}));
	}

	clearHoverListing(): void {
		this.setState(state => ({
			...state,
			hoverListingId: '',
		}));
	}
}

export default new ListingContainer();
