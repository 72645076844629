import React, { useState, useRef } from 'react';

import { Subscribe } from 'unstated';
import styled from 'styled-components';

import api from 'api';
import { searchContainer } from 'containers/search';
import { formatSlug } from 'utils';
import Button from 'lib/Button';

import {
	FilterContainer,
	PillContainer,
	PopupInner,
	PopupBottom,
	PopupButtonContainer,
} from './CommonStyles';
import { ClosablePopup } from './ClosablePopup';
import { SearchItem } from 'models/search';
import LocationSearch from 'components/LocationSearch';
import WarningTooltip from 'lib/WarningTooltip';
import { BREAKPOINT_MOBILE_PX } from 'constants/breakPoints';
import { useScreenWidth } from 'hooks/useScreenWidth';

const TooltipWrapper = styled.div`
	position: absolute;
	top: 56px;
	left: -80px;
`;

const TooltipContainer = styled.div`
	position: fixed;
	width: 240px;

	@media (max-width: ${BREAKPOINT_MOBILE_PX}px) {
		position: fixed;
		bottom: 16px;
		left: 16px;
		right: 16px;
		width: auto;
		top: unset;
	}
`;

interface LocationFilterProps {
	isOpen: boolean;
	showLocationWarningTooltip?: boolean;
	onOpenPopup(): void;
	onClosePopup(): void;
	onTooltipClose(): void;
}

const LocationFilter = ({
	isOpen,
	showLocationWarningTooltip,
	onOpenPopup,
	onClosePopup,
	onTooltipClose,
}: LocationFilterProps) => {
	const openPopupTriggerRef = useRef();
	const [searchList, setSearchList] = useState([]);
	const [selectedItems, setSelectedItems] = useState<SearchItem[]>([]);
	const width = useScreenWidth();

	const isMobile = width < BREAKPOINT_MOBILE_PX;

	const handleClosePopup = () => {
		onClosePopup();
	};

	const handlePillClick = () => {
		if (isOpen) {
			onClosePopup();
			return;
		}

		setSelectedItems(searchContainer.state.selectedItems);
		onOpenPopup();
	};

	const handleApply = () => {
		if (!selectedItems.length) {
			return;
		}
		searchContainer.setSelectedItems(selectedItems);
		localStorage.setItem('search_label', selectedItems[0] ? selectedItems[0].label : '');
		localStorage.setItem(
			'search_label_slug',
			selectedItems[0] ? formatSlug(selectedItems[0].label) : '',
		);
		onClosePopup();
	};

	const handleSearch = (term: string) => {
		api.searchV2.getSearchResults(term).then(({ data }) => {
			setSearchList(data);
		});
	};

	return (
		<FilterContainer>
			<Subscribe to={[searchContainer]}>
				{container => (
					<PillContainer
						ref={openPopupTriggerRef as any}
						active={Boolean(container.state.selectedItems[0])}
						onClick={handlePillClick}
					>
						{container.state.selectedItems.length > 1
							? `Location (${container.state.selectedItems.length})`
							: container.state.areaTitle || 'Location'}
					</PillContainer>
				)}
			</Subscribe>
			{showLocationWarningTooltip && (
				<TooltipWrapper>
					<TooltipContainer>
						<WarningTooltip
							text={
								isMobile
									? 'Add a location above to narrow down your search'
									: 'Add a location to narrow down your search'
							}
							onClose={onTooltipClose}
							hidePointer={isMobile}
						/>
					</TooltipContainer>
				</TooltipWrapper>
			)}
			{isOpen && (
				<ClosablePopup triggerElementRef={openPopupTriggerRef} onClose={handleClosePopup}>
					<PopupInner>
						<LocationSearch
							searchList={searchList}
							selectedSearchItems={selectedItems}
							onSearch={handleSearch}
							onSelectedSearchItemsChange={setSelectedItems}
							onSearchItemAdded={() => setSearchList([])}
						/>
					</PopupInner>
					<PopupBottom>
						<PopupButtonContainer>
							<Button isOutline noMargin onClick={handleApply}>
								Apply
							</Button>
						</PopupButtonContainer>
					</PopupBottom>
				</ClosablePopup>
			)}
		</FilterContainer>
	);
};

export default LocationFilter;
