import React, { Ref } from 'react';

import styled from 'styled-components';

import { Popup } from 'lib/Popup';
import { Divider } from 'lib/Divider';
import { Button } from 'lib/Button';
import { Cross } from 'lib/Cross';
import theme from 'config/theme';
import { LEASE_TYPE } from './constants';
import formatPriceText from './formatPriceText';
import { validFeatureCategories } from './validFeatureCategories';
import { PriceFilterConfig } from 'models/priceFilterConfig';

const { colors } = theme;

const SUB_TYPE = {
	Apartment: 'apartment',
	House: 'house',
	Cottage: 'cottage',
	Residence: 'residence',
};

const subTypeNameMappings = {
	[SUB_TYPE.Apartment]: 'Apartment',
	[SUB_TYPE.House]: 'House',
	[SUB_TYPE.Cottage]: 'Cottage',
	[SUB_TYPE.Residence]: 'Student residence',
};

const Container = styled.div`
	width: 100vw;
	height: 50vh;
	display: flex;
	flex-direction: column;
	max-width: 450px;
	margin: auto;

	@media (max-width: 768px) {
		max-width: 100vw;
	}
`;

const Header = styled.div`
	height: 60px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 24px;
	border-bottom: 1px solid ${colors.gray};
`;

const Content = styled.div`
	padding: 24px;
	flex: 1;
	overflow: auto;
`;

const Footer = styled.div`
	min-height: 60px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-top: 1px solid ${colors.gray};
	padding: 16px 24px;
`;

const TitleText = styled.div<{ noMargin?: boolean }>`
	font-size: 16px;
	font-weight: 600;
	margin-bottom: ${({ noMargin }) => (noMargin ? 0 : 16)}px;
`;

const GreyText = styled.div<{ noMargin?: boolean }>`
	color: ${colors.darkGray};
	line-height: 20px;
	margin-bottom: ${({ noMargin }) => (noMargin ? 0 : 16)}px;
`;

const Text = styled(GreyText)`
	color: ${colors.darkBlue};
`;

const ContentPair = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: 16px;
`;

interface Feature {
	name: string;
	category: string;
	id: number;
}

interface AddAlertPopupProps {
	title: string;
	params: any;
	features: Feature[];
	priceFilterConfig: PriceFilterConfig;
	triggerElementRef?: Ref<any>;
	isLoading?: boolean;
	onClose(): void;
	onSave(): void;
}

export const AddAlertPopup = ({
	title,
	params,
	features,
	priceFilterConfig,
	isLoading,
	triggerElementRef,
	onClose,
	onSave,
}: AddAlertPopupProps) => {
	const groupedFeaturesObj = features.reduce((acc: any, val: Feature) => {
		if (acc[val.category]) {
			return {
				...acc,
				[val.category]: [...acc[val.category], val],
			};
		}
		if (validFeatureCategories.includes(val.category)) {
			return {
				...acc,
				[val.category]: [val],
			};
		}
		return {
			...acc,
			[val.category]: [val],
		};
	}, {});

	const selectedGroupedFeatures = Object.keys(groupedFeaturesObj)
		.map(category => ({
			category,
			features: groupedFeaturesObj[category].filter(
				({ id }: Feature) => params.features && params.features.includes(id),
			),
		}))
		.sort((a, b) => (a.category.toLowerCase() > b.category.toLowerCase() ? 1 : -1))
		.filter(group => group.features.length)
		.map(group => ({
			...group,
			featureString: group.features.map(({ name }: Feature) => name).join(', '),
		}));

	const priceText = formatPriceText(
		params.min_price,
		params.max_price,
		priceFilterConfig.range_min,
		priceFilterConfig.range_max,
		priceFilterConfig.symbol,
	);

	return (
		<Popup rightAlign triggerElementRef={triggerElementRef} onClose={onClose}>
			<Container>
				<Header>
					<Cross onClick={onClose} />
					<TitleText noMargin>Save this search</TitleText>
					<Cross style={{ visibility: 'hidden' }} />
				</Header>
				<Content>
					<GreyText>
						Add an alert for this search and get notified by email when new listings are added.
					</GreyText>
					<TitleText>{title}</TitleText>
					<ContentPair>
						<GreyText noMargin>Price</GreyText>
						<Text noMargin>{priceText || 'any'}</Text>
					</ContentPair>
					{params.type === LEASE_TYPE.EntirePlace && (
						<ContentPair>
							<GreyText noMargin>Bedrooms</GreyText>
							<Text noMargin>{params.bedrooms || 0}</Text>
						</ContentPair>
					)}
					{!!params.sub_type && !!params.sub_type.length && (
						<ContentPair>
							<GreyText noMargin>Property type</GreyText>
							<Text noMargin>
								{params.sub_type.map((type: string) => subTypeNameMappings[type]).join(', ')}
							</Text>
						</ContentPair>
					)}
					<Divider />
					{selectedGroupedFeatures.map(group => (
						<div key={group.category}>
							<TitleText>{group.category}</TitleText>
							<GreyText>{group.featureString}</GreyText>
							<Divider />
						</div>
					))}
				</Content>
				<Footer>
					<Button isOutline isFullWidth isLoading={isLoading} onClick={onSave}>
						Add alert +
					</Button>
				</Footer>
			</Container>
		</Popup>
	);
};
