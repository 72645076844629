import React from 'react';
import styled from 'styled-components';
import dotLoader from 'assets/images/add-a-digs-v2/loading-dots.svg';
import theme from 'config/theme';
const { zIndices } = theme;

const SearchThisArea = ({ onClick, loading }) => {
	return <Container onClick={onClick}>{loading ? <Loading /> : 'Search this area'}</Container>;
};

export default SearchThisArea;

const Container = styled.div`
	border-radius: 8px;
	background-color: white;
	padding: 12px;
	position: absolute;
	top: 25px;
	width: 150px;
	z-index: ${zIndices.mapOverlay};
	margin: auto;
	font-weight: 600;
	box-shadow: rgba(0, 0, 0, 0.1) 0 0 10px;
`;

const Loading = styled.div`
    background-image: url("${dotLoader}");
    height: 18px;
    background-size: contain;
    background-position: center;
`;
