import React from 'react';

import ReactMapboxGl, { Marker, Popup, ZoomControl, MapContext } from 'react-mapbox-gl';
import { Subscribe } from 'unstated';

import PriceTag from './map/PriceTag';
import './map/style.css';
import ListingCardMap from './ListingCardMap';
import listingContainer from 'containers/listing';
import theme from 'config/theme';
import { MAPBOX_ACCESS_TOKEN } from 'config/keys';
import { ReactComponent as MarkerIcon } from 'assets/images/icons/map-icon.svg';

const { mapBoxTheme } = theme;

const Map = ReactMapboxGl({
	accessToken: MAPBOX_ACCESS_TOKEN,
	scrollZoom: false,
	center: [22.9375, -30.5595],
});
const MapboxMap = ({
	mapCenter,
	mapZoom,
	onMapMove,
	onMapZoom,
	selectListing,
	listings,
	selectedListingUuid,
	onListingCardClick,
	viewPort,
	addCenterPin,
	onMapLoad,
}) => {
	const getFitBounds = viewPort => {
		if (!viewPort) {
			return null;
		}

		return [
			[viewPort.southwest.lng, viewPort.southwest.lat],
			[viewPort.northeast.lng, viewPort.northeast.lat],
		];
	};

	const fitBounds = getFitBounds(viewPort);

	return (
		<Map
			// eslint-disable-next-line
			style={mapBoxTheme}
			center={mapCenter}
			zoom={!fitBounds ? [mapZoom] : undefined}
			containerStyle={{
				height: '100%',
				width: '100%',
			}}
			fitBounds={fitBounds}
			fitBoundsOptions={{ linear: true, duration: 0 }}
			onMoveEnd={onMapMove}
			onZoomEnd={onMapZoom}
			onClick={() => selectListing(null)}
			movingMethod={'jumpTo'}
			onStyleLoad={map => {
				onMapLoad(map);
			}}
		>
			<MapContext.Consumer>
				{map => {
					map.scrollZoom.enable();
				}}
			</MapContext.Consumer>
			<ZoomControl style={{ scale: '2' }} />
			{!!addCenterPin && (
				<Marker coordinates={mapCenter} anchor="bottom" style={{ zIndex: 1 }}>
					<MarkerIcon width={32} height={32} />
				</Marker>
			)}
			<Subscribe to={[listingContainer]}>
				{({ state }) =>
					listings &&
					listings.map((listing, i) => (
						<Marker
							key={i}
							coordinates={[listing.location.coords.longitude, listing.location.coords.latitude]}
							anchor="bottom"
							onClick={() => selectListing(listing.uuid)}
							style={
								listing.uuid === state.hoverListingId && {
									zIndex: 100,
								}
							}
						>
							<PriceTag
								selected={
									listing.uuid === selectedListingUuid || listing.uuid === state.hoverListingId
								}
								listing={listing}
							/>
						</Marker>
					))
				}
			</Subscribe>
			{selectedListingUuid &&
				listings
					.filter(listing => listing.uuid === selectedListingUuid)
					.map((listing, i) => (
						<Popup
							key={i}
							coordinates={[listing.location.coords.longitude, listing.location.coords.latitude]}
							onClick={() => selectListing(listing.uuid)}
						>
							<ListingCardMap {...listing} onClick={() => onListingCardClick(listing)} />
						</Popup>
					))}
		</Map>
	);
};

export default MapboxMap;
